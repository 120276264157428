import React, { useState, useEffect } from "react";
import CloseCircle from "../../assets/img/CloseCircle.svg";
import { Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";
import { RewardAPI } from "../../actions/Auth/Auth";
import {
  getRewardRedeemableId,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import { useSelector, useDispatch } from "react-redux";
import { updateCartRewards } from "../../actions/General/General";
import MessagesList from "../../config/messages.json";

function PromoCode({
  setAskForCode,
  codeType,
  setCodeType,
  getAllRewardsList,
  setIsCalculateAPIRequiredToCall,
}) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const dispatch = useDispatch();
  // CREATE LOCAL STATE
  const [promoCode, setPromoCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState("");

  const applyPromoCode = async () => {
    setIsSubmitted(true);
    setLoader(true);
    if (promoCode === "") {
      setErrorMessage("Enter Code");
    } else {
      if (codeType === "digital") {
        let data = RewardAPI({ code: promoCode });
        data = await Promise.resolve(data);

        // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
        if (data && data.generalError) {
          console.log("data.generalError",data.generalError)
          let errorMessage =
            data.generalError ===
            "The code you entered is either not valid or you do not qualify for the coupon"
              ? "This reward code is inactive or no longer available. Please come back soon with a new code!"
              : data.generalError ===
                "This promo code has already been used by some other guest."
              ? "This Digital Reward code has already been used by another guest. Please use another code."
              : data.generalError;
          // : "Enter Valid Code";
          // setErrorMessage("Enter Valid Code");
          setErrorMessage(errorMessage);

          // HIDE LOADER
          setLoader(false);
        } else {
          getAllRewardsList();
          setAskForCode(false);
          setCodeType("");
        }
      } else {
        let result = await getRewardRedeemableId(
          promoCode,
          "redemption_code",
          orderData
        );
        if (result.reward_id !== "") {
          // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
          let orderItem = _.cloneDeep(orderData);
          orderItem = await updateCartRewards(
            result.reward_id,
            // promoCode,
            "Promo Code",
            result.reward_amount,
            "redemption_code",
            orderItem,
            ""
          );

          // IF CLICKED REWARD IS ALREADY SELECTED, THEN EMPTY THE REWARDS OTHER ADD REWARD
          dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
          setAskForCode(false);
          setCodeType("");
          setIsCalculateAPIRequiredToCall(true);
        } else {
          setErrorMessage(MessagesList["Reward Can not be used"]);
        }
      }
    }
    setLoader(false);
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      applyPromoCode();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [applyPromoCode]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        setAskForCode(false);
        setCodeType("");
      }}
      aria-labelledby="draggable-dialog-title"
      className={`customize_modal`}
    >
      <DialogContent>
        <div className="d-flex flex-column">
          <img
            src={CloseCircle}
            className={"close-confirm-reward cursor-pointer"}
            onClick={() => {
              setAskForCode(false);
              setCodeType("");
            }}
          />
          <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-20 cmb-36">
            {codeType === "digital" ? "reward code" : "have a promo code?"}
          </h3>
          <h4 className="BrownSTDRegular font-size-1 text-grey4 text-center text-uppercase cmt-0 cmb-4">
            Enter your code below
          </h4>
          <span className="BrownSTDRegular font-size-0_9 text-grey4 text-center cmb-34 line-height-1_25 w-70 mx-auto">
            {codeType === "digital" ? "This code will unlock a reward!" : ""}
          </span>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="w-70 m-auto text-center">
              <Form.Control
                autoComplete={"false"}
                className="payment_field text-center"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                  setErrorMessage("");
                  setIsSubmitted(false);
                }}
                isInvalid={isSubmitted && errorMessage !== ""}
                placeholder={
                  codeType === "digital" ? "Enter Code" : "Enter Promo Code"
                }
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center">
              <Button
                type="submit"
                variant=""
                className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 bg-green3 rounded-full default-letter-spacing btn-half width-233 mt-3 mb-0`}
                onClick={applyPromoCode}
              >
                {loader ? <CircularProgress size={23} /> : "Submit"}
              </Button>
            </div>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PromoCode;
