import axios from "axios";
import store from "../../redux/store";
import { GuestUser, VerifyUser } from "../Auth/Auth";
import { DecryptReduxAuth } from "../General/General";
import { encryptData, rsaDecryption, decryptData } from "../RSA/Rsa";

const API_PATH = process.env.REACT_APP_API_URL;

const sendDeleteCallToSever = (API_URL) => {
  return axios.delete(API_URL);
};

const sendPatchCallToSever = (params, API_URL) => {
  return axios.patch(API_URL, params);
};

const sendPostCallToSever = (params, API_URL) => {
  return axios.post(API_URL, params);
};

const sendGetCallToSever = (API_URL) => {
  return axios.get(API_URL);
};
axios.interceptors.request.use(
  function (config) {
    let apiPath = API_PATH;
    let url = config.url;
    let auth = store.getState().auth.data;
    auth = DecryptReduxAuth(auth);
    //  DEFAULT ACCESS TOKEN URLS
    let defaultAccessTokenUrl = [
      "/users/startup",
      "/users/verifyuser/guest-login",
      "/users/punchh-bridge",
      "/users/verifyuser",
      "/users/otp-manager/send-otp",
      "/users/otp-manager/verify-otp",
    ];
    let nonEncryptedUrl = ["/users/startup", "/users/punchh-bridge"];

    url = url.replace(apiPath, "");
    // SET TOKEN HEADERS
    let authToken = auth.accessToken;

    defaultAccessTokenUrl.map((singleDefaultTokenUrl) => {
      if (url.startsWith(singleDefaultTokenUrl)) {
        authToken = auth.defaultAccessToken;
      }
    });
    let isEncodedNeeded = true;
    nonEncryptedUrl.map((singleEncryptionUrl) => {
      if (url.startsWith(singleEncryptionUrl)) {
        isEncodedNeeded = false;
      }
    });

    // CREATE HEADER OBJECT
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToken,
    };
    config.headers = headers;

    // UPDATE REQUEST URL
    if (
      isEncodedNeeded &&
      (config.method === "post" || config.method === "patch")
    ) {
      // GET REQUEST DATA AND ENCRYPT
      let encryptParams = config.data;
      // PERFORM ENCRYPTION ON DATA

      // GET PUBLIC KEY
      let publicKey = auth.serverPublicKey;
      // ENCRYPT Data

      if (!config._retry) {
        encryptParams = encryptData(encryptParams, publicKey);
        encryptParams = JSON.stringify(encryptParams);
      }
      config.data = encryptParams;
    }
    config.url = apiPath + url;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    let url = response.config.url;
    let auth = store.getState().auth.data;
    auth = DecryptReduxAuth(auth);
    let parsedData = response.data;
    let nonDecryptUrl = [
      API_PATH + "/users/startup",
      API_PATH + "/users/punchh-bridge",
    ];
    let privateTokenDecodeUrl = [
      API_PATH + "/users/verifyuser/guest-login",
      API_PATH + "/users/verifyuser",
    ];
    let isNeededToDecryptData = true;
    nonDecryptUrl.map((singleDecryptUrl) => {
      if (url.startsWith(singleDecryptUrl)) {
        isNeededToDecryptData = false;
      }
    });

    if (isNeededToDecryptData) {
      // SET PRIVATE KEY
      let privateKey = auth.serverPrivateKey;
      if (privateTokenDecodeUrl.indexOf(url) > -1) {
        privateKey = auth.privateKey;
      }

      // DECRYPT DATA
      const { handler, value } = parsedData;
      const rsaData = rsaDecryption(handler, privateKey);
      parsedData = decryptData(value, rsaData);
    }
    // RETURN SUCCESS RESPONSE
    if (response.status === 200 || response.status === 201) {
      return { data: parsedData, error: "" };
    } else {
      // RETURN ERROR RESPONSE
      return { data: {}, error: response.error.msg };
    }
  },
  async function (error) {
    if (error.response.status === 401) {
      let auth = store.getState().auth.data;
      auth = DecryptReduxAuth(auth);
      // CREATE DISPATCH OBJECT
      let dispatch = store.dispatch;
      if (auth.isGuest) {
        GuestUser(dispatch);
      } else {
        // GET USER PROFILE DETAIL FRO REDUX
        let userData = store.getState().profile;
        // CALL METHOD TO GET NEW TOKEN
        await VerifyUser(userData, dispatch);
      }
      const originalRequest = error.config;
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        window.location.href = "/logout";
      }
    } else {
      return {
        data: {},
        error: error.response.data.message
          ? error.response.data.message
          : typeof error.response.data.error === "string"
          ? error.response.data.error
          : error.response.data.error.msg,
      };
    }
    return Promise.reject(error);
  }
);

export {
  sendPostCallToSever,
  sendGetCallToSever,
  sendDeleteCallToSever,
  sendPatchCallToSever,
};
