import React, { useEffect } from "react";

// react-bootstrap components
import { Row, Col } from "react-bootstrap";
import SignUpForm from "./SignUpForm";
import ImgComponent from "./ImgComponent";
import { CreateGoogleAnalyticsAPI } from "../../actions/General/General";
function Signup(props) {
  useEffect(() => {
    CreateGoogleAnalyticsAPI("SignUpView", {});
  });
  return (
    <Row className="auth-forms">
      <Col className="p-0 pt-0 d-flex justify-content-center">
        <SignUpForm
          isGroup={
            props.searchId && props.searchId !== undefined ? true : false
          }
        />
      </Col>
      <Col className="p-0 image_page signup_page">
        <ImgComponent />
      </Col>
    </Row>
  );
}
export default Signup;
